import { createReducer, on, Action } from '@ngrx/store';
import { UdriveState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/udrive.action';
import { ActionType, UdriveFileTreeModel } from '@models/udrive.model';
import { TimeUtils } from '@utils/times';


export const reducer = createReducer(
	initialAppState.UdriveState,
	on(actions.setSidePanelOpen, (state, payload): UdriveState => {
		return {
			...state,
			sidePanelOpen: payload.value,
			currPath: '/',
			currAction: ActionType.NONE,
			currInputValue: '',
			currEditedNode: null,
			validInputValue: false,
			multiSelectionActive: false,
			multiSelectionItem: [],
			expandedFoldersList: [],
			detachNeeded: null
		}
	}),
	on(actions.loadUDrive, (state, payload): UdriveState => {
		return {
			...state,
			loadStore: payload.value,
			currAction: ActionType.NONE
		}
	}),
	on(actions.DetachNeeded, (state): UdriveState => {
		return {
			...state,
			loadStore: false
		}
	}),
	on(actions.getUdrive, (state): UdriveState => {
		return {
			...state,
			fileStored: [],
			loadStore: true,

		}
	}),
	on(actions.setUdrive, (state, payload): UdriveState => {
		return {
			...state,
			fileStored: [...payload.udriveResponse.uDriveFiles],
			sizeStore: { ...payload.udriveResponse.uDriveSize },
			canMultiselect: payload.chatAttachLength < 11,
			loadStore: false,
			uploadCount: payload.udriveResponse.uploadCount,
			multiSelectionItem: [],
			currFiles: [...payload.udriveResponse.uDriveFiles]
		}
	}),
	on(actions.deleteSelectedAttachments, (state, payload): UdriveState => {//actions.deleteUdriveItem,
		let fileToCheck = payload.files ? payload.files : [...state.multiSelectionItem]
		let folderList: number[] = [];
		let removeFolder: boolean = false;
		state.fileStored.map((obj: UdriveFileTreeModel) => {
			if (obj.extension === 'folder') folderList.push(obj.id)
		})
		removeFolder = folderList.some(numb => fileToCheck.includes(numb))
		let multiSelectionItem: number[] = state.currFiles.map((file: UdriveFileTreeModel) => file.id);
		multiSelectionItem = [...new Set([...multiSelectionItem, ...fileToCheck])]
		return {
			...state,
			loadStore: true,
			...(removeFolder && { multiSelectionItem }),
		}
	}),
	on(actions.selectFolder, (state, payload): UdriveState => {
		let currFiles: UdriveFileTreeModel[] = [];
		if (payload.value === '/') {
			currFiles = [...state.fileStored]
		} else {

			let currFolder = state.fileStored.filter(file => {
				return file.file_name === payload.value
			})[0]

			if (currFolder) currFiles = currFolder.subfile!
		}
		return {
			...state,
			currPath: payload.value,
			validInputValue: false,
			currEditedNode: null,
			currInputValue: '',
			multiSelectionActive: false,
			multiSelectionItem: [],
			currFiles: currFiles ? [...currFiles] : []
		}
	}),
	on(actions.selectAll, (state): UdriveState => {
		let multiSelect: number[] = [];
		let from = state.sidePanelOpen ? state.currFiles : state.currFiles.filter(obj => obj.extension !== 'folder')
		from.forEach((file) => {
			if (file.extension !== 'folder') {
				multiSelect.push(file.id);
			} else {
				if (file.subfile && file.subfile.length > 0) {
					file.subfile.forEach((file) => {
						multiSelect.push(file.id)
					})
				}
			}
		})
		return {
			...state,
			multiSelectionItem: [...multiSelect],
			canMultiselect: multiSelect.length < 11,
		}
	}),
	on(actions.setCurrAction, (state, payload): UdriveState => {
		return {
			...state,
			currAction: payload.value,
			validInputValue: false,
			currEditedNode: null,
			currInputValue: '',
			loadStore: false
		}
	}),
	on(actions.setValidInput, (state, payload): UdriveState => {
		return {
			...state,
			currInputValue: payload.value
		}
	}),
	on(actions.setCurrEditedNode, (state, payload): UdriveState => {
		return {
			...state,
			currEditedNode: payload.value,
			currInputValue: payload.value.file_name.replace(payload.value.extension!, ''),
			currAction: payload.value.extension === 'folder' ? ActionType.EDITFOLDER : payload.value.extension === 'url' ? ActionType.EDITURL : ActionType.EDITFILE,
		}
	}),


	on(actions.renamedUdriveFolder, (state): UdriveState => {
		let sidePanelOpen = state.sidePanelOpen;
		let currFiles = JSON.parse(JSON.stringify(state.currFiles));
		if (currFiles.length > 0) {
			currFiles.map((file: UdriveFileTreeModel) => file.path = '/' + state.currInputValue)
		}
		return {
			...state,
			currFiles: [...currFiles],
			...(!sidePanelOpen && { currPath: state.currInputValue }),
			currAction: ActionType.NONE,
			validInputValue: false,
			currEditedNode: null,
			urlUploadError: false,
			urlValidationStatus: 'none',
			currInputValue: ''
		}
	}),


	on(actions.resetAction, (state, payload): UdriveState => {
		return {
			...state,
			currAction: ActionType.NONE,
			validInputValue: false,
			currEditedNode: null,
			urlUploadError: false,
			urlValidationStatus: 'none',
			currInputValue: ''
		}
	}),

	on(actions.addUdriveFolderOk, (state, payload): UdriveState => {
		return {
			...state,
			currAction: ActionType.NONE,
			validInputValue: false,
			currEditedNode: null,
			urlUploadError: false,
			urlValidationStatus: 'none',
			currInputValue: '',
			currPath: payload.newPath
		}
	}),

	on(actions.setMultiSelectionList, (state, payload): UdriveState => {
		return {
			...state,
			multiSelectionItem: [...payload.list],
			canMultiselect: payload.canMultiselect,
			currAction: ActionType.NONE,
		}
	}),
	on(actions.toggleMultiSelection, (state): UdriveState => {
		return {
			...state,
			multiSelectionActive: !state.multiSelectionActive,
			multiSelectionItem: [],
			canMultiselect: true
		}
	}),
	on(actions.setExpandedFoldersList, (state, payload): UdriveState => {
		return {
			...state,
			expandedFoldersList: [...payload.value]
		}
	}),
	on(actions.setFileStatus, (state, payload): UdriveState => {
		let tmpAttachments: UdriveFileTreeModel[] = [...state.fileStored];
		tmpAttachments = tmpAttachments.map((attach: UdriveFileTreeModel) => {
			if (attach.id === payload.value) {
				return {
					...attach,
					status: 100
				}
			} else {
				return attach
			}
		});
		return {
			...state,
			fileStored: [...tmpAttachments]
		}
	}),
	on(actions.DetachNeeded, (state, payload): UdriveState => {
		return {
			...state,
			detachNeeded: payload.value
		}
	}),
	on(actions.ChatDetach, (state, payload): UdriveState => {
		return {
			...state,
			loadStore: true,
		}
	}),
	on(actions.ResetDetach, (state): UdriveState => {
		return {
			...state,
			detachNeeded: null,
			multiSelectionItem: [],
			canMultiselect: true
		}
	}),
	on(actions.urlUploadError, (state): UdriveState => {
		return {
			...state,
			urlUploadError: true,
			loadStore: false,
			urlValidationStatus: 'none'
		}
	}),
	on(actions.addUdriveFolder, (state): UdriveState => {
		return {
			...state,
			urlValidationStatus: 'none',
			urlUploadError: false,
			loadStore: true
		}
	}),
	on(actions.addUdriveUrl, (state): UdriveState => {
		return {
			...state,
			urlValidationStatus: 'inprogress',
			urlUploadError: false
		}
	}),
	on(actions.CalculateFileEmbeddings, (state, payload): UdriveState => {
		let currStored = [...state.fileStored];
		let selectedeFile = findFileById(currStored, payload.attachId)!;
		selectedeFile = {
			...selectedeFile,
			status: 0
		}
		if (selectedeFile!.path === '/') {
			//nella root
			currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === selectedeFile.id)] = { ...selectedeFile };
		} else {
			//nel folder
			let parentFolder = findFileParent(currStored, selectedeFile.path);
			parentFolder.subfile![parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === selectedeFile.id)] = { ...selectedeFile };
			currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
		}
		return {
			...state,
			fileStored: [...currStored]
		}
	}),
	on(actions.SetEmailAddress, (state, payload): UdriveState => {
		return {
			...state,
			emailsAddress: [...payload.emailAddress]
		}
	}),
	on(actions.updateFileNotes, (state, payload): UdriveState => {
		let currId = state.currEditedNode!.id;
		let currPath = state.currEditedNode!.path;
		let parentFile: UdriveFileTreeModel;
		let currStored = [...state.fileStored];
		if (currPath === '/') {
			parentFile = JSON.parse(JSON.stringify(currStored.find((file: UdriveFileTreeModel) => {
				return file.id === currId
			})));
			parentFile.notes = [...payload.notes];
		} else {
			parentFile = JSON.parse(JSON.stringify(currStored.find((file: UdriveFileTreeModel) => {
				return file.file_name === currPath.replace('/', '');
			})));
			parentFile?.subfile!.map((file: UdriveFileTreeModel) => {
				if (file.id === currId) {
					file.notes = [...payload.notes]
				}
			});
		}
		currStored[currStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFile.id)] = { ...parentFile! };
		return {
			...state,
			currEditedNode: {
				...state.currEditedNode!,
				notes: [...payload.notes]
			},
			fileStored: [...currStored],
			loadStore: true
		}
	}),
	on(actions.addFakeFolder, (state): UdriveState => {
		let fakeFolder: UdriveFileTreeModel = {
			id: -1,
			id_user: '',
			file_name: '',
			extension: 'folder',
			notes: null,
			path: '/',
			size: 0,
			status: 0,
			upload_date: null,
			embeddings: null,
			subfile: [],
			src: null,
			emb_lang: null
		}
		return {
			...state,
			fileStored: [...state.fileStored, { ...fakeFolder }]
		}
	}),
	on(actions.cancelFakeFolder, (state): UdriveState => {
		let fileCopy = [...state.fileStored]
		fileCopy.pop();
		return {
			...state,
			fileStored: [...fileCopy],
			currAction: ActionType.NONE
		}
	}),
	on(actions.updateUdrive, (state, payload): UdriveState => {
		let currFilesStored = [...state.fileStored];
		let currFiles = [...state.currFiles];
		let newFile = payload.updatedFile;
		let oldFile = findFileById(currFilesStored, newFile.id);
		//update object info
		if (oldFile!.path === newFile.path) {
			//update object inside root
			if (newFile.path === '/') {
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
				currFiles[currFiles.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
			} else {
				//update object inside folder
				let parentFolder = findFileParent(currFilesStored, newFile.path);
				parentFolder.subfile![parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
				currFiles[currFiles.findIndex((file: UdriveFileTreeModel) => file.id === newFile.id)] = { ...newFile };
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
			}
			//move object
		} else {
			if (oldFile!.path === '/' && newFile!.path !== '/') {
				//da root a folder
				let newParentFolder = findFileParent(currFilesStored, newFile!.path);
				if (!newParentFolder.subfile) newParentFolder.subfile = [];
				newParentFolder.subfile.push({ ...newFile });
				newParentFolder.subfile.sort((a: UdriveFileTreeModel, b: UdriveFileTreeModel) => a.id - b.id)
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
				currFilesStored.splice(currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === newFile!.id), 1)
				currFiles.splice(currFiles.findIndex((file: UdriveFileTreeModel) => file.id === newFile!.id), 1)

			} else if (oldFile!.path !== '/' && newFile!.path === '/') {
				//da folder a root
				currFilesStored.push({ ...newFile });
				let oldParentFolder = findFileParent(currFilesStored, oldFile!.path);
				oldParentFolder.subfile!.splice(oldParentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				currFiles.splice(currFiles.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				if (oldParentFolder.subfile!.length <= 0) oldParentFolder.subfile = null;
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === oldParentFolder.id)] = { ...oldParentFolder };

			} else if (oldFile!.path !== '/' && newFile!.path !== '/') {
				//da folder a folder
				let newParentFolder = findFileParent(currFilesStored, newFile!.path);
				if (!newParentFolder.subfile) newParentFolder.subfile = [];
				newParentFolder.subfile.push({ ...newFile });
				newParentFolder.subfile.sort((a: UdriveFileTreeModel, b: UdriveFileTreeModel) => a.id - b.id)
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
				let oldParentFolder = findFileParent(currFilesStored, oldFile!.path);
				oldParentFolder.subfile!.splice(oldParentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				currFiles.splice(currFiles.findIndex((file: UdriveFileTreeModel) => file.id === oldFile!.id), 1);
				if (oldParentFolder.subfile!.length <= 0) oldParentFolder.subfile = null;
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === oldParentFolder.id)] = { ...oldParentFolder };
			}
		}
		
		currFilesStored = sortFileByDate(currFilesStored)


		

		if (state.currEditedNode && (state.currEditedNode!.id === payload.updatedFile.id)) {
			return {
				...state,
				fileStored: [...currFilesStored],
				currFiles: [...currFiles],
				currEditedNode: { ...payload.updatedFile },
				loadStore: false
			}
		} else {
			return {
				...state,
				fileStored: [...currFilesStored],
				currFiles: [...currFiles],
				loadStore: false
			}
		}
	}),
	on(actions.updateNewUdrive, (state, payload): UdriveState => {
		let currFiles = [...state.currFiles]
		let currFilesStored = [...state.fileStored];
		let newFile = payload.updatedFile;
		if (payload.updatedFile.path === '/') {
			//nella root
			currFilesStored.unshift({ ...payload.updatedFile })
			currFiles.unshift({ ...payload.updatedFile })
		} else {
			//nel folder
			let newParentFolder = findFileParent(currFilesStored, newFile!.path);
			if (!newParentFolder.subfile) newParentFolder.subfile = [];
			newParentFolder.subfile.unshift({ ...newFile });
			currFiles.unshift({ ...newFile })
			currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.file_name === newFile!.path.replace('/', ''))] = { ...newParentFolder };
		}
		currFiles = sortFileByDate(currFiles) 
		currFilesStored = sortFileByDate(currFilesStored)
		return {
			...state,
			fileStored: [...currFilesStored],
			currFiles: [...currFiles],
			loadStore: false
		}
	}),
	on(actions.removeUdriveItem, (state, payload): UdriveState => {
		let folderList: number[] = [];
		let removeFolder: boolean = false;
		state.fileStored.map((obj: UdriveFileTreeModel) => {
			if (obj.extension === 'folder') folderList.push(obj.id)
		})
		removeFolder = folderList.some(numb => payload.removedFiles.includes(numb))
		let currFilesStored = [...state.fileStored];
		let currFiles: UdriveFileTreeModel[] = [];
		payload.removedFiles.forEach((fileId: number) => {
			let deletedfile = findFileById(currFilesStored, fileId)!
			if (deletedfile.path === '/') {
				currFilesStored.splice(currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === deletedfile.id), 1)
				currFiles = currFilesStored.filter((file: UdriveFileTreeModel) => file.extension !== 'folder')
			} else {
				let parentFolder = findFileParent(currFilesStored, deletedfile.path);
				parentFolder.subfile!.splice(parentFolder.subfile!.findIndex((file: UdriveFileTreeModel) => file.id === deletedfile.id), 1);
				if (parentFolder.subfile!.length <= 0) parentFolder.subfile = null;
				currFilesStored[currFilesStored.findIndex((file: UdriveFileTreeModel) => file.id === parentFolder.id)] = { ...parentFolder };
				if (parentFolder.subfile !== null) {
					currFiles = [...parentFolder.subfile!]
				} else {
					currFiles = []
				}
			}
		})
		return {
			...state,
			fileStored: [...currFilesStored],
			loadStore: false,
			multiSelectionItem: [],
			currFiles,
			...(removeFolder && { currPath: '/' }),
			multiSelectionActive: false,
			canMultiselect:true
		}
	}),
	on(actions.createNewChatAttachments, (state): UdriveState => {
		return {
			...state,
			loadStore: true
		}
	}),
	on(actions.resetUdriveState, (state): UdriveState => {
		return {
			...state,
			...initialAppState.UdriveState
		}
	}),
	on(actions.sortFileList, (state, payload): UdriveState => {
		let currFiles = [...state.currFiles]
		let key: keyof UdriveFileTreeModel = (payload.active as keyof UdriveFileTreeModel);
		if (payload.direction === 'asc') {
			currFiles.sort((a, b) => {
				if (key === 'upload_date') {
					return TimeUtils.convertToTimestamp(a[key] as string) - TimeUtils.convertToTimestamp(b[key] as string);
				} else if (key === 'file_name') {
					return (a[key] as string).localeCompare(b[key] as string);
				} else {
					return a.size - b.size;
				}

			});
		}
		if (payload.direction === 'desc') {
			currFiles.sort((a, b) => {
				if (key === 'upload_date') {
					return TimeUtils.convertToTimestamp(b[key] as string) - TimeUtils.convertToTimestamp(a[key] as string)
				} else if (key === 'file_name') {
					return (b[key] as string).localeCompare(a[key] as string);
				} else {
					return b.size - a.size;
				}
			});
		}
		if (payload.direction === '') {
			currFiles.sort((a, b) => a.id - b.id);
		}
		return {
			...state,
			currFiles: [...currFiles]
		}
	})

);


function findFileById(arr: UdriveFileTreeModel[], findId: number): UdriveFileTreeModel | null {
	for (const { subfile, id } of arr) {
		if (id === findId) {
			return arr[arr.findIndex((file: UdriveFileTreeModel) => file.id === findId)];
		}
		if (subfile && subfile.length) {
			const childId = findFileById(subfile, findId);
			if (childId !== null) {
				return subfile[subfile.findIndex((file: UdriveFileTreeModel) => file.id === findId)];
			}
		}
	}
	return null;
}


function findFileParent(storedFiles: UdriveFileTreeModel[], pathToFind: string): UdriveFileTreeModel {
	return JSON.parse(JSON.stringify(storedFiles.find((file: UdriveFileTreeModel) => {
		return file.file_name === pathToFind.replace('/', '');
	})));
}

function sortFileByDate(currFilesStored: UdriveFileTreeModel[]): UdriveFileTreeModel[] {
	currFilesStored = currFilesStored.filter(file => file.id >= 0);
	let file = currFilesStored.filter( item => item.extension !== 'folder').sort((a,b)=> new Date(b.upload_date!).getTime() - new Date(a.upload_date!).getTime());
	let folder = currFilesStored.filter( item => item.extension === 'folder');
	return folder.concat(file);
}


export function UdriveReducer(state: UdriveState | undefined, action: Action): UdriveState {
	return reducer(state as UdriveState, action as Action);
}
