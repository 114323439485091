import { createReducer, on, Action } from '@ngrx/store';
import { ChatState, initialAppState } from '../app.interface';
import * as actions from '@store/actions/chat.action';
import { AttachmentGetModel, ChatSidebarItemModel, MessageType, ResponseCategoryUiCardType } from '@models/chat.model';
import { WebsocketMessages } from '@models/generic.model';

export const reducer = createReducer(
	initialAppState.ChatState,
	on(actions.SetChat, actions.SetChatUknow, (state, payload): ChatState => {
		let list: ChatSidebarItemModel[] = JSON.parse(JSON.stringify(payload.value.adamConversation));
		let isCurr = false;
		let chatTitle = '';
		let hasAttachments = false;
		let hasUKnow = false;
		let temp: ChatSidebarItemModel[] = list.map((conv: ChatSidebarItemModel) => {
			if (conv.convId === state.chatId) {
				isCurr = true;
				chatTitle = conv.title;
				hasAttachments = conv.hasAttachments;
				hasUKnow = conv.hasUKnow;
			}
			return conv
		});
		list = [...temp];
		return {
			...state,
			chatList: !state.chatToLoad ? [...list] : state.chatList ? [...state.chatList!, ...list] : [...list],
			searchChat: false,
			...(payload.value.adamConversation.length <= 0 && { searchChatString: '' })
		}
	}),
	on(actions.CleanChatSearch, (state): ChatState => {
		return {
			...state,
			searchChat: true,
			chatToLoad: true,
			chatList: [],
			searchChatString: ''
		}
	}),
	on(actions.SearchChat, (state, payload): ChatState => {
		return {
			...state,
			searchChat: true,
			chatToLoad: false,
			searchChatString: payload.string
		}
	}),
	on(actions.NewChatAdded, (state, payload): ChatState => {
		let newChatList = [...state.chatList!];
		newChatList = newChatList.filter((chat: ChatSidebarItemModel) => {
			return chat.lastMsg !== ''
		})
		newChatList = [payload.value, ...newChatList];
		return {
			...state,
			chatId: payload.value.convId,
			chatTitle: payload.value.title,
			conversationType: payload.value.conversationType,
			incomingMsg: false,
			chatList: [...newChatList],
			hasAttachments: false,
			attachments: null,
			expertise: payload.value.expertise
		}
	}),
	on(actions.SelectChat, (state, payload): ChatState => {
		return {
			...state,
			chatId: payload.value.convId,
			chatTitle: payload.value.title,
			conversationType: payload.value.conversationType,
			currMessages: [],
			incomingMsg: payload.value.msgType === MessageType.Q ? true : false,
			hasAttachments: payload.value.hasAttachments,
			attachments: null,
			hasUKnow: payload.value.hasUKnow,
			expertise:  payload.value.expertise ? payload.value.expertise : 'base',
		}
	}),
	on(actions.SendMessage, (state, payload): ChatState => {
		let msg = {
			name: 'You',
			answer: [
				{
					text: payload.value.msg,
					answer_type: null,
					reasoning: null,
					time: payload.value.time
				}
			],
			msgType: 'Q'
		}
		return {
			...state,
			currMessages: [
				...state.currMessages,
				msg
			],
			incomingMsg: true,
			sentByUser: true,
			isMacro: false,
			macroType: ''
		}
	}),
	on(actions.updateQuestionReasoning, (state, payload): ChatState => {
		let tmpMsg = JSON.parse(JSON.stringify([...state.currMessages]));
		tmpMsg[tmpMsg.length - 1].answer[0].reasoning = payload.value;
		return {
			...state,
			currMessages: [...tmpMsg],
		}
	}),
	on(actions.ReciveMessage, (state, payload): ChatState => {
		if (payload.value.conversationId === state.chatId) {
			return {
				...state,
				currMessages: [
					...state.currMessages,
					payload.value
				],
				incomingMsg: false,
				sentByUser: false,
			}
		} else {
			return {
				...state,
			}
		}
	}),
	on(actions.MakeChatHistory, (state, payload): ChatState => {
		return {
			...state,
			currHistory: [
				...payload.value
			]
		}
	}),
	on(actions.RestoreChat, (state, payload): ChatState => {
		return {
			...state,
			currMessages: [
				...payload.value.msg
			],
			currHistory: [
				...payload.value.hst
			],
			attachments: payload.value.attachments ? { ...payload.value.attachments } : null,
			loadChat: false,
			hasAttachments: payload.value.hasAttachments
		}
	}),
	on(actions.RestoreShareChat, (state, payload): ChatState => {
		return {
			...state,
			chatId: payload.value.chatId,
			chatTitle: payload.value.chatTitle,
			shareDate: payload.value.createDate,
			currMessages: [
				...payload.value.msg
			],
			currHistory: [
				...payload.value.hst
			],
			uknowOwnerId: payload.value.chatOwner
		}
	}),
	on(actions.LoadChat, (state, payload): ChatState => {
		return {
			...state,
			loadChat: payload.value
		}
	}),
	on(actions.ManualUpdateChatTitle, (state, payload): ChatState => {
		return {
			...state,
			chatTitle: state.chatId === payload.chatId ? payload.newTitle : state.chatTitle
		}
	}),
	on(actions.UpdateChatTitle, (state, payload): ChatState => {
		return {
			...state,
			chatTitle: payload.value
		}
	}),
	on(actions.ChatToDel, (state, payload): ChatState => {
		let chatToDel = [...state.chatToDel]
		if (chatToDel.indexOf(payload.value) < 0) {
			chatToDel.push(payload.value);
		} else {
			chatToDel.splice(chatToDel.indexOf(payload.value), 1);
		}
		return {
			...state,
			chatToDel: [...chatToDel]
		}
	}),
	on(actions.onRemoveChatExchange, (state, payload): ChatState => {
		let history = [...state.currHistory];
		let messages = [...state.currMessages];
		if (payload.value.index !== undefined) {
			history.splice(payload.value.index, 2);
			messages.splice(payload.value.index, 2);
		} else {
			history = history.filter((hst: any) => {
				return hst[0].id_adam_conversation_history !== payload.value.id
			});
			messages = messages.filter((msg: any) => {
				return msg.answer[0].id_adam_conversation_history !== payload.value.id
			});
		}
		if (messages.length > 0 || state.hasAttachments) {
			return {
				...state,
				currHistory: [...history],
				currMessages: [...messages]
			}
		} else {
			return {
				...state,
				chatTitle: '',
				chatId: '',
				currMessages: [],
				currHistory: [],
				conversationType: ""
			}
		}

	}),
	on(actions.UpdateChatLastMessage, (state, payload): ChatState => {
		let tmpPld = JSON.parse(payload.value)
		let chatList = [...state.chatList!].map((obj: ChatSidebarItemModel) => {
			if (obj.convId === tmpPld.convId) {
				obj = {
					...obj,
					lastMsg: tmpPld.lastMsg,
					lastMsgTime: tmpPld.lastMsgTime//TimeUtils.setTime(tmpPld.lastMsgTime)
				}
			}
			return obj
		})
		return {
			...state,
			chatList: [...chatList]
		}
	}),
	on(actions.CleanChatStatus, (state): ChatState => {
		return {
			...state,
			chatTitle: '',
			chatId: '',
			currMessages: [],
			currHistory: [],
			conversationType: ""
		}
	}),
	on(actions.CleanRemoveChat, (state): ChatState => {
		return {
			...state,
			chatToDel: []
		}
	}),
	on(actions.setChatType, (state, payload): ChatState => {
		let tmpList = JSON.parse(JSON.stringify(state.chatList));
		tmpList.map((obj: ChatSidebarItemModel) => {
			if (obj.convId === state.chatId) {
				obj.conversationType = payload.value
			}
		})
		return {
			...state,
			chatList: [...tmpList],
			conversationType: payload.value
		}
	}),
	on(actions.EmptyChat, (state): ChatState => {
		return {
			...initialAppState.ChatState,
		}
	}),
	on(actions.setChatAttachments, (state, payload): ChatState => {
		return {
			...state,
			attachments: { ...payload.value }
		}
	}),

	on(actions.updateChatAttachments, (state, payload): ChatState => {
		let currAttachments = JSON.parse(JSON.stringify(state.attachments));
		let found = currAttachments.attachments!.some((attach: AttachmentGetModel) => attach.id === payload.updatedAttach.id);
		if (found) currAttachments.attachments[currAttachments.attachments.findIndex((attach: AttachmentGetModel) => attach.id === payload.updatedAttach.id)].uDriveFile = { ...payload.updatedAttach };
		return {
			...state,
			attachments: { ...currAttachments }
		}
	}),
	on(actions.EmptyUknowChat, (state): ChatState => {
		return {
			...state,
			currMessages: [],
			currHistory: []
		}
	}),
	on(actions.SelectUknowChat, (state, payload): ChatState => {
		return {
			...state,
			loadChat: true,
			chatTitle: payload.attachments.title!,
			attachments: payload.attachments,
			chatId: payload.convId,
			conversationType: ResponseCategoryUiCardType.UKNOW,
			incomingMsg: false,
			hasAttachments: true,
			isUknowChat: true,
			uknowOwnerId: payload.attachments.user_id
		}
	}),
	on(actions.updateUknowChat, actions.CleanChatAttachments, (state): ChatState => {
		return {
			...state,
			loadChat: true,
			uknowWillDeleted: false
		}
	}),
	on(actions.createUknowChat, (state): ChatState => {
		return {
			...state,
			loadChat: true,
			uknowWillDeleted: false,
		}
	}),
	on(actions.CalculateFileEmbeddings, (state, payload): ChatState => {
		if (state.attachments?.attachments) {
			let attachments = [...state.attachments?.attachments!].map((attach: AttachmentGetModel) => {
				if (attach.id === payload.attachId) {
					return {
						...attach,
						uDriveFile: {
							...attach.uDriveFile,
							status: 0
						}
					}
				} else {
					return attach
				}
			})
			return {
				...state,
				attachments: {
					...state.attachments!,
					attachments
				}
			}
		} else {
			return {
				...state
			}
		}
	}),
	on(actions.SetChatId, (state, payload): ChatState => {
		return {
			...state,
			chatId: payload.convId
		}
	}),
	on(actions.UknowWillDeleted, (state, payload): ChatState => {
		return {
			...state,
			uknowWillDeleted: payload.uknow,
			loadChat: false
		}
	}),
	on(actions.CancelUknowWillDeleted, (state): ChatState => {
		return {
			...state,
			uknowWillDeleted: false
		}
	}),
	on(actions.WSMessage, (state, payload): ChatState => {
		let newList!: ChatSidebarItemModel[];
		if (state.chatList) {
			newList = [...state.chatList!];
			let index = newList.findIndex(x => x.convId === payload.updateObject.convId);
			switch (payload.wsEvent) {
				case WebsocketMessages.NEWMESSAGERECEIVED:
				case WebsocketMessages.NEWMESSAGESEND:
					if (index >= 0) {
						newList[index] = { ...payload.updateObject }
					} else {
						newList.push(payload.updateObject)
					}
					break
				case WebsocketMessages.NEWMESSAGESEND:
				case WebsocketMessages.READCONVERSATION:
				case WebsocketMessages.TITLEUPDATE:
				case WebsocketMessages.DELETEUKNOWHOST:
				case WebsocketMessages.CONVERSATIONDRIVEDELETE:
				case WebsocketMessages.CONVERSATIONDRIVEUPDATE:
					newList[index] = { ...payload.updateObject }
					break
				case WebsocketMessages.PINUPDATE:
					if (newList[index]) {
						newList[index] = { ...payload.updateObject }
					} else {
						newList.push(payload.updateObject)
					}
					break
			}
			newList = newList.sort(function (a, b) {
				let A = isNaN(new Date(a.lastMsgTime).getTime()) ? new Date().getTime() : new Date(a.lastMsgTime).getTime();
				let B = isNaN(new Date(b.lastMsgTime).getTime()) ? new Date().getTime() : new Date(b.lastMsgTime).getTime();
				return B - A;
			})
			newList.forEach(function (item: ChatSidebarItemModel, i: number) {
				if (item.isPinned) {
					newList.splice(i, 1);
					newList.unshift(item);
				}
			});
			return {
				...state,
				chatList: [...newList],
				...(state.chatId === payload.updateObject.convId && { chatTitle: payload.updateObject.title }),
				...(payload.wsEvent === WebsocketMessages.DELETEUKNOWHOST && { hasUKnow: payload.updateObject.hasUKnow })
			}
		} else {
			return {
				...state
			}
		}
	}),
	on(actions.WSMessageAddConv, (state, payload): ChatState => {
		let newList: ChatSidebarItemModel[] = [...state.chatList!];
		if (payload.wsEvent === WebsocketMessages.NEWCONVERSATIONADAM) {
			let index = newList.findIndex(x => x.convId === (payload.convList as unknown as ChatSidebarItemModel).convId);
			if (index >= 0) {
				return {
					...state,
				}
			} else {
				return {
					...state,
					chatList: [(payload.convList as unknown as ChatSidebarItemModel), ...newList]
				}
			}

		} else if (payload.wsEvent === WebsocketMessages.DELETECONVERSATIONS) {
			(payload.convList as unknown as string[]).filter((convId: string) => {
				newList = newList.filter(x => x.convId !== convId)
			})
			return {
				...state,
				chatList: [...newList]
			}
		} else if (!state.sentByUser) {
			return {
				...state
			}
		} else {
			return {
				...state
			}
		}
	}),
	on(actions.WSMessageUpdatequickActionParagraph, (state, payload): ChatState => {
		let currMessages = JSON.parse(JSON.stringify(state.currMessages));
		currMessages.map((msg: any) => {
			if (msg.msgType === 'A' && msg.id_adam_conversation_history === payload.idAnswer) {
				msg.answer[0].data = { ...payload.paragraphsData }
			}
		})
		return {
			...state,
			currMessages: [...currMessages]
		}
	}),
	on(actions.WSMessageUpdateImageDescriptionData, (state, payload): ChatState => {
		if (state.currMessages.length >= 2) {
			let newData = JSON.parse(payload.imagesData.update_image_description);
			let curMsgs = JSON.parse(JSON.stringify(state.currMessages))
			curMsgs.map((msg: any) => {
				if (msg.id_adam_conversation_history === payload.imagesData.id_adam_conv) {
					msg.answer[0].data = [...newData]
				}
			})
			return {
				...state,
				currMessages: [...curMsgs]
			}
		} else {
			return {
				...state,
			}
		}
	}),
	on(actions.resetChatState, (state): ChatState => {
		return {
			...state,
			...initialAppState.ChatState
		}
	}),
	on(actions.MacroDocument, (state, payload): ChatState => {
		return {
			...state,
			isMacro: true,
			macroType: payload.kind,
			sentByUser: false
		}
	}),
	on(actions.resetMacro, (state): ChatState => {
		return {
			...state,
			isMacro: false,
			macroType: ''
		}
	}),


	on(actions.setChatExpertise, (state, payload): ChatState => {
		return {
			...state,
			expertise: payload.expertise
		}
	})
);


export function ChatReducer(state: ChatState | undefined, action: Action): ChatState {
	return reducer(state as ChatState, action as Action);
}