import { environment } from '@environments/environment';


export const API_URL = {
	getAuthToken: environment.apiHostPublic + 'token',
	getSpeechToken: environment.apiHostPublic + 'token/speechservices',
	getForgotPassCode: environment.apiHostPublic + 'user/forgotPassword',
	updatePassCode: environment.apiHost + 'user/updatepassword',
	googleRevokeToken: environment.apiHost + 'google/revoketoken/',
	googleToken: environment.apiHost + 'google/token',
	revokePartialgoogleToken: environment.apiHost + 'google/revokepartialtoken',
	getGoogleCalendar: environment.apiHost + 'calendar/read/',
	updateGoogleCalendar: environment.apiHost + 'calendar/update/',
	googlePlaceImage: environment.apiHostPublic + 'google/placeimage/',
	getUser: environment.apiHost + 'user/read',
	updateUser: environment.apiHost + 'user/patch',
	getSettingsByIdUser: environment.apiHost + 'setting/readbyuser/',
	createSetting: environment.apiHost + 'setting/create',
	updateSetting: environment.apiHost + 'setting/update',
	createUser: environment.apiHostPublic + 'user/create',
	deleteUser: environment.apiHost + 'user/disable',
	query: environment.apiHostPublic + 'maestroai/query',
	conversationById: environment.apiHost + 'conversation/read',
	//conversationById: 'https://apidev.synapsia.ai/conversation/readimpersonation/a9f32037-bda8-44a3-841b-b2e80ed0cc09/',
	deleteConversationById: environment.apiHost + 'conversation/delete/',
	deleteConversationList: environment.apiHost + 'conversation/deletelist/',
	deleteConversationExchange: environment.apiHost + 'conversation/deletemsg/',
	cleanConversation: environment.apiHost + 'conversation/deletemsglist/',
	conversationKbById: environment.apiHost + 'conversation/readKB/',
	deleteConversationKbById: environment.apiHost + 'conversation/deleteKB/',
	conversetionGuest: environment.apiHost + 'conversation/readguest/',
	//conversationTaskUpdate: environment.apiHost + 'conversation/taskupdate',
	//textToSpeech: environment.apiHost+'maestroai/texttospeech',
	textToSpeech: environment.apiHostPublic + 'maestroai/idanstexttospeech',
	textToSpeechBlock: environment.apiHost + 'tts/block/',
	readGraphById: environment.apiHost + 'personalgraph/read/',
	getContactsList: environment.apiHost + 'Contact/Read',
	addContact: environment.apiHost + 'Contact/Create',
	updateContact: environment.apiHost + 'Contact/Update',
	removeContact: environment.apiHost + 'Contact/Delete',
	readHtmlMail: environment.apiHost + 'email/readlivebyid/',
	readMaiaConv: environment.apiHost + 'conversation/readmaiaconversation',
	readMaiaConvPinned: environment.apiHost + 'conversation/readmaiaconversationpinnedpaged/',
	//readMaiaConvPinned: 'https://apidev.synapsia.ai/conversation/readmaiaconversationimpersonation/a9f32037-bda8-44a3-841b-b2e80ed0cc09',
	readMaiaConvPaged: environment.apiHost + 'conversation/readmaiaconversationpaged/',
	stopAnswer: environment.apiHost + 'conversation/interruptconversation/',
	sendMail: environment.apiHostPublic + 'mail/send',
	search: environment.apiHost + 'conversation/conversationcontactsearch',
	updateConversationTitle: environment.apiHost + 'conversation/titleupdate/',
	updateConversationPinned: environment.apiHost + 'conversation/pinupdate/',
	updateAdamAnswer: environment.apiHost + 'conversation/updateadamanswer/',
	userReportCreate: environment.apiHost + 'userreport/create',
	lastPpgEdit: environment.apiHost + 'personalgraph/updatelastppgedit',
	getPpgDictionary: environment.apiHost + 'personalgraph/readuserdictionary',
	//updatePpgListUser: environment.apiHost+'personalgraph/updatelistuser',
	updatePpgCudUser: environment.apiHost + 'personalgraph/cuduser',
	//createNodeInfo: environment.apiHost+'personalgraph/createuser',
	//deleteNodeInfo: environment.apiHost+'personalgraph/delete/',
	//deleteListNodeInfo: environment.apiHost+'personalgraph/deletelist',
	getBannedCat: environment.apiHost + 'personalgraph/readbannedppg',
	updateBannedCat: environment.apiHost + 'personalgraph/updatebannedppg',
	downloadPpg: environment.apiHost + 'personalgraph/downloadppg',
	readMaiaCalendar: environment.apiHost + 'calendar/readmaiacalendar',
	creaeteMaiaCalendar: environment.apiHost + 'calendar/createmaiacalendar',
	deleteMaiaCalendar: environment.apiHost + 'calendar/delete/',
	searchEvents: environment.apiHost + 'calendar/search',
	createMaiaEvent: environment.apiHost + 'calendar/createmaiaevent',
	deleteMaiaEvent: environment.apiHost + 'calendar/deletemaiaevent/',
	updateMaiaEvent: environment.apiHost + 'calendar/updatemaiaevent',
	readSharedChat: environment.apiHostPublic + 'chatshare/readchat/',
	generateShareChat: environment.apiHost + 'chatshare/create/',
	readSharedConversations: environment.apiHost + 'chatshare/read',
	deleteSharedConversation: environment.apiHost + 'chatshare/delete/',
	udriveUploadFile: environment.apiHost + 'udrive/upload',
	udriveUploadUrl: environment.apiHost + 'udrive/uploadurl',
	udriveRead: environment.apiHost + 'udrive/readdrive',
	udriveCreateFolder: environment.apiHost + 'udrive/createfolder',
	udriveDeleteItem: environment.apiHost + 'udrive/delete/',
	udriveDeleteListItems: environment.apiHost + 'udrive/deletelist',
	udriveRenameFolder: environment.apiHost + 'udrive/renamefolder',
	udriveRenameFile: environment.apiHost + 'udrive/rename',
	udriveUpdateNotes: environment.apiHost + 'udrive/updatenotes/',
	udriveQuickActions: environment.apiHost + 'udrive/quickaction',
	udriveSaveInUdrive: environment.apiHost + 'udrive/uploadimage',
	udriveMove: environment.apiHost + 'udrive/movelist',
	udriveEmailRead: environment.apiHost + 'udriveemail/read',
	udriveEmailCreate: environment.apiHost + 'udriveemail/create',
	udriveEmailUpdate: environment.apiHost + 'udriveemail/update',
	udriveEmailDelete: environment.apiHost + 'udriveemail/delete/',
	calculateFileEmbeddings: environment.apiHost + 'udrive/calculatefileurlembeddings/',
	convDriveCreate: environment.apiHost + 'conversationdrive/create',
	convDriveUpdate: environment.apiHost + 'conversationdrive/update',
	convDriveCleanAttachments: environment.apiHost + 'conversationdrive/delete/',
	convDriveGetAttachments: environment.apiHost + 'conversationdrive/readattachments/',
	//convDriveGetAttachments: environment.apiHost+'conversationdrive/readattachmentsimpersonation/6fb3a7f4-bc4a-4d9c-96d5-fe41b1140449/',
	convDriveDetach: environment.apiHost + 'conversationdrive/deletelistbyudriveIdlist',
	uknowCreateProfile: environment.apiHostPublic + 'uknow/createprofiling',
	uknowReadById: environment.apiHost + 'uknow/read/',
	uknowVerify: environment.apiHost + 'uknow/verify/',
	uknowCreate: environment.apiHost + 'uknow/create/',
	uknowUpdate: environment.apiHost + 'uknow/update/',
	uknowDelete: environment.apiHost + 'uknow/delete/',
	uKnowReadAttachments: environment.apiHostPublic + 'uknow/readattachmentsbyhost',
	uknowReadAll: environment.apiHost + 'uknow/read',
	uknowReadGuestConversation: environment.apiHost + 'conversation/readguest/',
	searchMaiaConversation: environment.apiHost + 'conversation/searchmaiaconversation',
	videoShareRead: environment.apiHost + 'videoshare/read',
	videoShareReadByKey: environment.apiHost + 'videoshare/readbykey/',
	videoShareCreate: environment.apiHost + 'videoshare/create',
	videoShareUpdateTitle: environment.apiHost + 'videoshare/updatetitle',
	videoShareDownload: environment.apiHost + 'videoshare/download/',
	videoShareDownloadLocal: environment.apiHost + 'videoshare/downloadlocal/',
	videoShareDownloadFree: environment.apiHost + 'videoshare/downloadfree',
	coinRead: environment.apiHost + 'coin/read',
	impersonateUser: environment.apiHost + 'token/',
	impersonateUserLogout: environment.apiHost + 'token/impersonatelogout'
}