import { createAction, props } from '@ngrx/store';
import { MaiaApplicationPreferences, ContactsSettings, SpentActionModel, NewCoinsTotal } from '@models/personalSettings.model';
import { SettingModel, SettingNotification, SettingsKeyName, SourceManagement, UserInformations } from '@models/setting.model';
import { GoogleSettingsState } from '../app.interface';
import { GoogleCalendarModel } from '@models/google.model';

export const GetSettings = createAction(
	'[StorePersonalSettings] GetSettings',
	props<{ value: string }>()
);

export const SetSettings = createAction(
	'[StorePersonalSettings] SetSettings',
	props<{ value: SettingModel[] }>()
);

export const SetInformationUser = createAction(
	'[StorePersonalSettings] SetInformationUser',
	props<{ value: UserInformations }>()
);

export const UpdateInformationUser = createAction(
	'[StorePersonalSettings] UpdateInformationUser',
	props<{ value: UserInformations }>()
);

export const UpdateInformationUserDone = createAction(
	'[StorePersonalSettings] UpdateInformationUserDone',
	props<{ value: UserInformations }>()
);

export const SetGoogleToken = createAction(
	'[StorePersonalSettings] SetGoogleToken',
	props<{ value: GoogleSettingsState }>()
);

export const SetApplication = createAction(
	'[StorePersonalSettings] SetApplication',
	props<{ value: any }>()
);

export const SetMaiaApplicationPreferences = createAction(
	'[StorePersonalSettings] SetMaiaApplicationPreferences',
	props<{ value: MaiaApplicationPreferences }>()
);

export const SetSourceManagement = createAction(
	'[StorePersonalSettings] SetSourceManagement',
	props<{ value: SourceManagement }>()
);

export const SetMaiaApplicationPreferencesSuccess = createAction(
	'[StorePersonalSettings] SetMaiaApplicationPreferencesSuccess'
);

export const SetVisualPreferencesError = createAction(
	'[StorePersonalSettings] SetVisualPreferencesError',
	props<{ error: any }>()
);

export const UpdateNotification = createAction(
	'[StorePersonalSettings] UpdateNotification',
	props<{ value: SettingNotification }>()
);

export const SetNotification = createAction(
	'[StorePersonalSettings] SetNotification'
);

export const SaveContactSettings = createAction(
	'[StorePersonalSettings] Save Contact Settings',
	props<{ value: ContactsSettings }>()
);

export const SaveContactSettingsSuccess = createAction(
	'[StorePersonalSettings] Save Contact Settings Success',
	props<{ value: ContactsSettings }>()
);

export const UpdateTimezone = createAction(
	'[StorePersonalSettings] Update Timezone',
	props<{ value: string }>()
);

export const SetCalendars = createAction(
	'[StorePersonalSettings] Set calendar',
	props<{ value: GoogleCalendarModel[] }>()
);

export const UpdateEnabledCalendars = createAction(
	'[StorePersonalSettings] Update Active calendar',
	props<{ value: GoogleCalendarModel[] }>()
);

export const setShareUser = createAction(
	'[StorePersonalSettings] Set share chat user info',
	props<{ value: { userId?: string, lang?: string } }>()
);


export const setUknowUser = createAction(
	'[StorePersonalSettings] Set uknow chat user info',
	props<{ value: { email: string, guest_id: string, name: string, surname: string } }>()
);


export const updateSetting = createAction(
	'[StorePersonalSettings] Update setting',
	props<{ value: { key: SettingsKeyName; user_id: string; id: number | undefined; value: string; } }>()
);

export const createSetting = createAction(
	'[StorePersonalSettings] Create setting',
	props<{ value: { key: SettingsKeyName; user_id: string; id: number | undefined; value: string; } }>()
);

export const SetCurrPath = createAction(
	'[StorePersonalSettings] Set current path application',
	props<{ currPath: string }>()
);


export const CoinsSpent = createAction(
	'[StorePersonalSettings] Update credit',
	props<{ spentAction: SpentActionModel }>()
);

export const CoinsTotal = createAction(
	'[StorePersonalSettings] Update total',
	props<{ spentAction: NewCoinsTotal }>()
);


export const setAblyChannels = createAction(
	'[StorePersonalSettings] Set Ably channels',
	props<{ channels: { [key: string]: string }[] }>()
);

export const UpdateDone = createAction(
	'[StorePersonalSettings] updateDone'
);
